import React, { useEffect } from "react";
import Layout from "../components/layout"

import { StaticImage } from "gatsby-plugin-image"

const PageNotFound = () => {

  useEffect(() => {
    // Verifica se estamos no ambiente de navegador
    if (typeof window !== 'undefined' && typeof document !== 'undefined') {
      // Cria o elemento de script
      const script = document.createElement('script');

      // Define o conteúdo do script
      script.innerHTML = `
        window.dataLayer = window.dataLayer || [];
        function gtag(){dataLayer.push(arguments);}
        gtag('js', new Date());
        gtag('config', 'G-SE4NY559V3');
      `;

      // Insere o script no início do body
      document.body.insertBefore(script, document.body.firstChild);

      // Limpeza ao desmontar o componente
      return () => {
        document.body.removeChild(script);
      };
    }

  }, []);  // O array vazio significa que este useEffect só é executado uma vez após o primeiro render


   return (
    <Layout>
      <div className="flex justify-center items-center flex-col">
        <div className="opacity-30 absolute"><StaticImage src="../images/404.png" alt="404 not found" /></div>
        <div>
         <h1 className="mt-20">Página Não Encontrada</h1>
         </div>
         <div className="bg-gradient-to-r from-transparent via-black to-transparent opacity-90 mt-64 items-center">
         <p className="font-thin text-2xl font-sans text-orange-300 text-center">Se você veio de uma URL externa como https://www.dobitaobyte.com.br/sirene-com-arduino, 
          experimente trocar para <b>https://www.manualdomaker.com/article</b>/sirene-com-arduino. Ou,
          muito mais fácil, digite o termo na busca rápida!
         </p>
         </div>
      </div>
      </Layout>
  )
}

export default PageNotFound